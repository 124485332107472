(function ($) {
    "use strict";


    let _SEARCH = null;

    let _search_core = {
        init: function () {
            _SEARCH = this;
            this.setup();
            this.run_filter();
        },

        setup: function () {
            $(".js--search, .js-m--search-trigger").on("click", function () {
                $("body").find(".search--popup-container").addClass("is-open");
                setTimeout(function () {
                    var input = $("body").find(".search--popup-container #search_text");
                    input.focus();
                }, 300);
            });

            // Close search popup on clicking the close button
            $(".js--search-close").on("click", function () {
                $("body").find(".search--popup-container").removeClass("is-open");
            });

            // Close search popup on "Esc" key press
            $(document).on("keydown", function (event) {
                if (event.key === "Escape" || event.keyCode === 27) {
                    // Check for the Escape key
                    $(".search--popup-container").removeClass("is-open");
                }
            });

            // Close search popup on clicking outside of its children
            $(document).on(
                "click",
                ".search--popup-container.is-open",
                function (event) {
                    if (event.target === this) {
                        // Direct click on the container
                        $(this).removeClass("is-open");
                    }
                }
            );
        },


        run_filter:function(){


            function setInitialFilterState() {
                const urlObj = new URL(window.location.href);
                const type = urlObj.searchParams.get('type');

                // Set the active state of the buttons based on the `type` parameter
                if (type === 'post') {
                    $('[data-filter="post"]').addClass('active');
                    $('[data-filter="gallery"]').removeClass('active');
                } else if (type === 'gallery') {
                    $('[data-filter="gallery"]').addClass('active');
                    $('[data-filter="post"]').removeClass('active');
                } else {
                    // If no type parameter, both filters are active
                    $('[data-filter="post"]').addClass('active');
                    $('[data-filter="gallery"]').addClass('active');
                }
            }

            // Set the initial state of the filter buttons when the page loads
            setInitialFilterState();

            // Handle filter button clicks
            $('.filter-buttons').on('click', '.filter-btn', function(e) {
                e.preventDefault();
                const $button = $(this);
                const wasActive = $button.hasClass('active');
                const $otherButton = $('.filter-btn').not($button);

                // Prevent deactivation of the last active button
                if (wasActive && $otherButton.hasClass('active') === false) return;

                $button.toggleClass('active');

                // Get the current URL and remove everything after the first path segment
                let currentUrl = window.location.href;
                currentUrl = currentUrl.replace(/\/page\/\d+\/?$/, ''); // Remove `/page/{page_number}`
                currentUrl = currentUrl.replace(/\/seite\/\d+\/?$/, ''); // Remove `/seite/{page_number}`

                const urlObj = new URL(currentUrl);
                const params = urlObj.searchParams;
                /*const pathParts = urlObj.pathname.split('/').filter(part => part.trim() !== ''); // Split path into parts
                let basePath = `/${pathParts[0]}/`;

                if(pathParts[0] === 'schlagworte'){
                    basePath = `/${pathParts[0]}/${pathParts[1]}`;
                }
                let // Keep only the first path segment (e.g., /category/ or /tag/)

                // Rebuild the URL with only the first path segment
                urlObj.pathname = basePath;*/

                // Determine active filters
                const postActive = $('[data-filter="post"]').hasClass('active');
                const galleryActive = $('[data-filter="gallery"]').hasClass('active');

                // Update the `type` parameter based on active filters
                if (postActive && galleryActive) {
                    params.delete('type'); // Show both posts and galleries
                } else if (postActive) {
                    params.set('type', 'post'); // Show only posts
                } else {
                    params.set('type', 'gallery'); // Show only galleries
                }

                // Reload the page with the updated URL
                window.location.href = urlObj.toString();
            });
            $('.filter-reset').on('click',function(e){
                e.preventDefault();

                // Get the current URL
                const currentUrl = new URL(window.location.href);

                // Remove the `type` parameter from the URL
                currentUrl.searchParams.delete('type');

                // Redirect to the updated URL
                window.location.href = currentUrl.toString();
            });


        },
    }

    if (window.ONBN.modules.search === true) {
        _search_core.init();
    }

})(jQuery);